<!-- 高校大类专业匹配全景报告 -->
<template>
  <div class="main">
    <div class="main-title">
      <span
        style="left: 0; padding-left: 2rem; position: absolute; color: #2EA9DF;"
        >VIP版</span
      ><span>高校大类专业匹配全景报告</span>
      <div class="print-btn" @click="print()">
        <div class="print-btn-text-wrapper">
          <span>打印</span><span>报告</span>
        </div>
      </div>
    </div>
    <div class="main-parts-wrapper">
      <div class="main-part">
        <div class="part-title">所有专业类别匹配结果</div>
        <!-- 条形图 -->
        <div class="part-content" id="container" :style="chartheight">
        </div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <!-- <a
        class="lj-btn lj-btn-third lj-btn-block main-button"
        @click="jumpPage('HomeMajorStdResultPage3')"
        >上一页</a
      > -->
      <a
        class="lj-btn lj-btn-default lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
      <!-- <a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        @click="jumpPage('HomeMajorStdResultPage6')"
        >下一页</a
      > -->
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import { queryMatchingVal } from './../../../../api/major'
import Highcharts  from './../../../../Public/utils/highcharts/highcharts'
export default {
  data: function () {
    return {
      matchingValue: [76, 23],
      major: ['计算机', '金融'],
      chartheight: {
        'min-height': '4800px'
      },
      chart: {}
    }
  },
  computed: {
    chartOptions: function () {
      return {
        chart: { type: 'bar' },
        xAxis: {
          categories: this.major,
          title: {
            text: '专业类',
            style: { fontSize: '16px' }
          },
          labels: {
            step: 1,
            staggerLines: 1,
            style: { fontSize: '14px' }
          }
        },
        title: { text: null },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '匹配度',
            align: 'high',
            style: { fontSize: '16px' }
          },
          labels: { format: '{value} %' }
        },
        legend: { enabled: !1 },
        tooltip: { valueDecimals: 2, valueSuffix: ' %' },
        plotOptions: {
          bar: {
            dataLabels: { enabled: !0, format: '{y} %' }
          },
          series: { pointWidth: 30 }
        },
        credits: { enabled: !1 },
        series: [{ name: '匹配度', data: this.matchingValue }]
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  created: function () {
    if (this.isMobile !== null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage5Mobile'
      })
      return
    }
    queryMatchingVal({}).then(res => {
      if (res.rescode == 200) {
        console.log('queryMatchingVal', res)
        this.matchingValue = res.data.matchingValue
        this.major = res.data.major
        this.chartheight = {
          'min-height': `${this.major.length * 50}px`
        }
        this.initChart()
      }
    })
  },
  methods: {
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    },
    jumpPage: function (dest) {
      if (this.$route.query.page === 'reportcenter') {
        this.$router.push({ path: 'reportCenter' })
        return
      }
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    initChart () {
      this.chart = new Highcharts.Chart(
        document.getElementById('container'),
        this.chartOptions
      )
    },
  }
}
</script>

<style lang="less">
@import url('./../../../../Public/resources/css/templates/home/major/std_result4.min.css');
.main-title {
  padding: 0;
}
</style>
